.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portfolio h1 {
    font-size: 50px;
}

@media (max-width: 768px) {
    .portfolio h1 {
        font-size: 20px;
    }
}

.portfolio ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
}

@media (max-width: 768px) {
    .portfolio ul {
        margin: 10px 0;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.portfolio .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .portfolio .container {
        width: 100%;
    }
}

.portfolio .container .item {
    width: 220px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid #f0efef;
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    transition: all 0.5s ease;
    cursor: pointer;
}

@media (max-width: 768px) {
    .portfolio .container .item {
        width: 130px;
        height: 100px;
    }
}

.portfolio .container .item h3 {
    position: absolute;
    font-size: 20px;
}

.portfolio .container .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.portfolio .container .item:hover {
    background-color: #15023a;
}

.portfolio .container .item:hover img {
    opacity: 0.2;
    z-index: 0;
}