.topbar {
    width: 100%;
    height: 70px;
    background-color: white;
    color: #15023a;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;
}

.topbar .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar .wrapper .left {
    display: flex;
    align-items: center;
}

.topbar .wrapper .left .logo {
    font-size: 40px;
    font-weight: 700;
    text-decoration: none;
    color: inherit; /*This will make it color becom the color awround it*/
    margin-right: 40px;
}

.topbar .wrapper .left .itemContainer {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

@media (max-width: 768px) {
    .topbar .wrapper .left .itemContainer {
        display: none;
    }
}

.topbar .wrapper .left .itemContainer .icon {
    font-size: 18px;
    margin-right: 5px;
}

.topbar .wrapper .left .itemContainer span {
    font-size: 15px;
    font-weight: 500;
}

.topbar .wrapper .right .hamburger {
    width: 32px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.topbar .wrapper .right .hamburger span {
    width: 100%;
    height: 3px;
    background-color: #15023a;
    transform-origin: left;
    transition: all 1s ease;
}

.topbar.active {
    background-color: #15023a;
    color: white;
}

.topbar.active .hamburger span:first-child {
    background-color: white;
    transform: rotate(45deg);
}

.topbar.active .hamburger span:nth-child(2) {
    opacity: 0;
}

.topbar.active .hamburger span:last-child {
    background-color: white;
    transform: rotate(-45deg);
}