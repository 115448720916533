.portfolioList {
    font-size: 14px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer; /*Change cursor from I to pointer*/
}

@media (max-width: 768px) {
    .portfolioList {
        margin-right: 20px;
    }
}

.portfolioList.active {
    background-color: #15023a;
    color: white;
}